.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(10px); /* Размытие фона */
  -webkit-backdrop-filter: blur(10px)
}
.titleModal {
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
  padding: 20px 0 20px 20px;
}

.modalContainer {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 517px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.messagesContainer {
  height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}


.backLink {
  border-radius: 18px;
  color: black;
  background: rgba(255, 255, 255, 0.10);
  display: flex;
  align-items: center;
  justify-content: space-between;
   width: 100%;
}
.avatarChronology {
  width: 26px;
  height: 26px;
  background: steelblue;
  border-radius: 16px;
}

.containerMessage {
  margin: 20px 20px;

}
.closeIcon {
  cursor: pointer;
  margin-right: 20px;
}

.borderBottom {
  border-bottom: 1px solid #E1E1E1;
}

.containerSender {
  display: flex;
  align-items: center;

}

.message {
  display: inline-block;
  max-width: 200px;
  border-radius: 10px;
  border: 1px solid #ECECEC;
  background: var(--gray-light, #F7F7F7);
  padding: 8px 15px;
  margin-top: 14px;
}

.containerSender {
  & .avatarChronology {
    margin-right: 8px;
  }
  & .userName {
    margin-right: 10px;
    font-weight: 600;
    color: #5A5A5A;
  }
}

.containerFileAdd {
  margin: 20px;
  border-radius: 12px;
  border: 0.5px solid #EDEDED;
  background: #FAFAFA;
  display: inline-flex;
  padding: 10px 16px;
  align-items: center;
  gap: 10px;
}

.addIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.containerInput {
  margin: 0 20px;
  max-width: 481px;
  position: relative;
  padding-bottom: 20px;
}

.inputComment {
  width: 100%;
  border: 1px solid #E1E1E1;
  max-width: 481px;
  border-radius: 16px;
  padding: 12px 20px;
}
.arrowIcon {
  position: absolute;
  top: 12px;
  right: 16px;
  width: 16px !important;
  height: 16px;
}

.you {
  align-items: flex-end;

}
.other {
  align-items: flex-start;
}

.alignEnd {
  align-self: flex-end;
  text-align: right;
}

.alignStart {
  align-self: flex-start;
  text-align: left;
}

.messageDate {
  color: #5A5A5A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.time {
  margin-left: 10px;
}

.date  {
  margin-right: 10px;
}

@media (max-width: 400px) {
  .containerInput {
    margin: 0 10px;
  }
  .containerFileAdd {
    margin: 20px 10px;
  }

  .containerMessageRecipient {
    margin: 10px;
  }

  .titleModal {
    font-size: 30px;
  }

}