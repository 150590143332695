.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(10px); /* Размытие фона */
  -webkit-backdrop-filter: blur(10px)
}

.wrapperBtnModal {
  cursor: pointer;
}

.titleModal {
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
  padding: 20px 0 20px 20px;
}

.inputComment {
  width: 100%;
  border: 1px solid #E1E1E1;
  max-width: 481px;
  border-radius: 16px;
  padding: 12px 20px;
  margin-bottom: 20px;
}

.backLink {
  width: 44px;
  padding: 10px;
  margin: 40px 0 0 40px;
  border-radius: 18px;
  color: black;
  background: rgba(255, 255, 255, 0.10);
  position: absolute;
  right: 1%;
  top: -6%;
  cursor: pointer;
}


.modalContainer {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 517px;
   position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.mainAddFile {
  display: flex;
  border-radius: 12px;
  border: 0.5px solid #EDEDED;
  background: #FAFAFA;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  width: 155px;
  margin-left: 25px;
  cursor: pointer;
  margin-top: 20px;
}
.plus {
  font-size: 18px;
}

.addFile {
  color: #5A5A5A;
  font-weight: 600;
  line-height: normal;

}

.mainInput {
  max-width: 481px;
  margin: 16px 25px 0 25px;
  position: relative;
}

.arrowForward {
  position: absolute;
  font-size: 18px !important;
  top: 18px;
  right: 15px;
  cursor: pointer;
}

.mainInput textarea {
  padding: 14px 20px;
  border: 1px solid #ECECEC;
  max-width: 481px;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 25px;
}

@media (max-width: 1536px) {
  .modalContainer {
    width: 80%;
  }

}


@media (max-width: 834px) {
  .modalContainer {
    width: 80%;
  }

}


@media (max-width: 600px) {
  .modalContainer {
    width: 90%;
    height: auto;
  }
  .dataBlock {
    margin: 20px;
  }
  .titleModal {
    font-size: 40px;
  }
  .dataItem {
    display: block;
    margin-top: 10px ;
  }
  .dataItem span {
    display: block;
  }
  .blockBtn {
    display: block;
  }
  .btn {
    margin: 5px 0 5px 5px;
    width: 97%;
  }
  .borderBottom:nth-child(2) {
    border-bottom: 1px solid #E1E1E1;
    margin: 18px 4px ;
  }
  .dataInputPlan {
    margin: 10px 20px;
  }
}




@media (max-width: 350px) {
  .modalContainer {
    width: 80%;
  }
}